<template>
  <div>
    <a
      rel="noreferrer"
      target="_blank"
      href="https://assoft.co"
      class="position-absolute  bottom-0 end-0 m-3"
      >Optima Health by Assoft</a
    >
    <form
      @submit.prevent="change"
      class="p-3 position-absolute top-50 start-50 translate-middle border rounded public-form"
    >
      <h3 class="text-center">Nueva Contraseña</h3>
      <p class="text-center">
        Ingrese la nueva contraseña para su cuenta
      </p>
      <div class="mb-3 inputIcon">
        <label for="signupPassword" class="col-6 form-label">Contraseña</label>
        <input
          type="password"
          class="form-control col-6"
          id="signupPassword"
          v-model="password"
          required
        />
        <svg class="bi" fill="currentColor">
          <use xlink:href="/img/icons/bootstrap-icons.svg#lock" />
        </svg>
      </div>
      <div class="mb-3 inputIcon">
        <label for="signupPasswordConfirm" class="col-6 form-label"
          >Confirmar Contraseña</label
        >
        <input
          type="password"
          class="form-control col-6"
          id="signupPasswordConfirm"
          v-model="passwordConfirm"
          required
        />
        <svg class="bi" fill="currentColor">
          <use xlink:href="/img/icons/bootstrap-icons.svg#lock" />
        </svg>
      </div>
      <div class="d-grid gap-2">
        <button type="submit" class="btn btn-primary shadow-primary">
          Cambiar Contraseña
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Session from "@/modules/session";

export default {
  data() {
    return {
      password: "",
      passwordConfirm: ""
    };
  },
  name: "ChangePassword",
  methods: {
    change() {
      new Session()
        .changePassword(this.$route.params.token, this.passwordConfirm)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Contraseña actualizada, por favor inicie sesión",
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          setTimeout(() => {
            this.$router.push({ name: "Login" });
          }, 3000);
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Contraseña no válida",
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
        });
    }
  }
};
</script>
